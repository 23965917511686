import React from 'react';
import Spotify1 from './Spotify1';
import Spotify from './Spotify';
import SearchLyrics from './searchLyrics';
import AudioPlayer from './AudioPlayer';
import Weather1 from './Weather1';
import './text.css';

const Welcome = () => {
  return (
    <div>
      <div className="background-image">
        <img src="https://picsum.photos/2000/1000" alt="Background Image" />
      </div>
      <h1>Welcome to Mayank's Website!</h1>
      <button onClick={() => window.location.href = './Spotify1'}>Go to Spotify</button>
      <button onClick={() => window.location.href = './SearchLyrics'}>Go to Lyrics Finder</button>
      <button onClick={() => window.location.href = './Weather1'}>Go to Weather App</button>
      <button onClick={() => window.location.href = './AudioPlayer'}>Go to player</button>
      
      </div>
      
  );
}

export default Welcome;
