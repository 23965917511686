// MovieDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import SpotifyPlayer from './AudioPlayer';
import Spotify1 from './Spotify1';
import { Button } from '@mui/material';
import './all.css';
import Welcome from './Welcome';
import AudioPlayer from './AudioPlayer';
const MovieDetail = () => {
  const { id } = useParams();
  const details = [
    {
        Title:"Poop",
        Actor:"Shit (Poop)",
        image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.explicit.bing.net%2Fth%3Fid%3DOIP.K7jfl4CnLZ4JLuVUtbeDtQHaHa%26pid%3DApi&f=1&ipt=f2a32c143b40972d56ceb08082f25fe1974798e2119c2948ec7d5189841061a9&ipo=images",
        songs: ['https://archive.org/download/oliver-tree-life-goes-on/Oliver%20Tree%20Life%20Goes%20On.mp3?_=1']
  
      
      },
    {
        Title: "The Shawshank Redemption",
        Actor: "Morgan Freeman",
        image: "https://external-content.duckduckgo.com/iu/?u=https://m.media-amazon.com/images/M/MV5BNDE3ODcxYzMtY2YzZC00NmNlLWJiNDMtZDViZWM2MzIxZDYwXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_.jpg&f=1&h=500",
        songs: ['https://archive.org/download/movie-soundtracks/Movie%20Soundtracks/12%20Shawshank%20Redemtion.mp3']
        },
  
      {
          Title:"3 Idiots",
          Actor:"Amir Khan",
          image:"https://duckduckgo.com/i/36963509869b0559.jpg",
          songs: ['https://archive.org/download/3Idiots2009_879/3Idiots-01-AalIzzWellportalcineindio.com.mp3']  
      },
      {
          Title:"Stranger Things",
          Actor:"Millie Booby Brown",
          image:"https://m.media-amazon.com/images/M/MV5BMDZkYmVhNjMtNWU4MC00MDQxLWE3MjYtZGMzZWI1ZjhlOWJmXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_FMjpg_UX1000_.jpg",
          songs: ['https://archive.org/download/tvtunes_32890/Stranger%20Things.mp3']  
      },
      {
          Title:"Money Heist",
          Actor:"Alvaro Morte",
          image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.IP0fCypZtfhZv3FYhJSbMgHaHa%26pid%3DApi&f=1&ipt=590456ef28b1ca97c0b3ad910b9608a851d34324ed317d79eaa594c68b0f271e&ipo=images",
          songs: ['https://archive.org/download/maramba-bella-ciao-remix-official-clip-mp-3-128-k/Maramb%C3%A1%20-%20Bella%20Ciao%20Remix%20%28Official%20Clip%29%28MP3_128K%29.mp3']  
      },
      {
          Title:"Panchyat",
          Actor:"Jitendr Kumar",
          image:"https://external-content.duckduckgo.com/iu/?u=https://m.media-amazon.com/images/M/MV5BOGRmMjc4MjEtM2E4YS00NjM5LWIwYzUtYTFlNTdhMTRhNmJjXkEyXkFqcGdeQXVyMTExMTIzMTA5._V1_.jpg&f=1&h=500",
          songs: ['https://dl1.jattpendu.com/download/320k-dhhin/Panchayat-Title.mp3']  
      },
      {
          Title:"Kota Factory",
          Actor:"Mayur More",
          image:"https://i0.wp.com/popculturepartner.in/wp-content/uploads/2020/09/Kota-Factory-Is-The-Best-Indian-Series-In-2019.jpg?fit=750%2C450&ssl=1",
          songs: ['https://dl1.jattpendu.com/download/320k-dygcx/Jeetu-Bhaiya.mp3']      
        },
      {
          Title:"Inception",
          Actor:"Cillian Murphy",
          image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.JsYPBTLAel-gKfhICwrKsgHaK-%26pid%3DApi&f=1&ipt=20a31bb0c1011520ade1546252a5f2f1871051a6e535cf8d4fa27ab1962bcce3&ipo=images",
          songs: ['https://archive.org/download/InceptionSoundtrackHD12TimeHansZimmer/Inception%20Soundtrack%20HD%20-%20%2312%20Time%20%28Hans%20Zimmer%29.mp3']
        },
      {
          Title:"Oopenhiemer",
          Actor:"Cillian Murphy",
          image:"https://external-content.duckduckgo.com/iu/?u=https://m.media-amazon.com/images/M/MV5BMDBmYTZjNjUtN2M1MS00MTQ2LTk2ODgtNzc2M2QyZGE5NTVjXkEyXkFqcGdeQXVyNzAwMjU2MTY@._V1_.jpg&f=1&h=500"
         ,songs:['https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ea/3e/02/ea3e025e-b637-8e82-e4ac-f7a128d9bd9c/mzaf_4027490872531175894.plus.aac.p.m4a']
        },
      {
          Title:"Squid Game",
          Actor:"Jung Ho-yeon",
          image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.AN1hHvK3LroP-Sg3zLLJ4gAAAA%26pid%3DApi&f=1&ipt=30da5863a1b8a08e35cb0d4e9e8689855adb2438e76ad02d36518dbd61eed8bf&ipo=images"
          ,songs:['https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/1d/48/9e/1d489e27-be1f-0f25-265b-319024dcea38/mzaf_16516802563548467538.plus.aac.p.m4a']
        },
      {
          Title:"Breaking Bad",
          Actor:"Bryan Cranston",
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Breaking_Bad_logo.svg/640px-Breaking_Bad_logo.svg.png"
          ,songs:['https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/bb/77/e3/bb77e3e0-de2b-6189-d6ca-c8d4a3953145/mzaf_7084513712626221396.plus.aac.p.m4a']
        },
      {
          Title:"Naruto",
          Actor:"Yuri Lowenthal",
          image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.Mm0sYfCEJ0k_AMWxd0ZtrwHaKS%26pid%3DApi&f=1&ipt=7a4a2f26e0efa91bdbf8a3804a65922b961e6f97e16f3c1b44abfd52989b6998&ipo=images"
      },
      {
          Title:"The Witcher",
          Actor:"	Lauren Schmidt Hissrich",
          image:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.2JsGojL-WhI9UibER1isFAHaKX%26pid%3DApi&f=1&ipt=fca0fbfa4c8a7054f1369f0323e9d87ca28e1fcb80c763e99a8d8423d6a5721a&ipo=images"
          ,songs:['https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e2/87/f6/e287f62c-2661-36e5-e067-fe90932050f9/mzaf_4631921717851954886.plus.aac.p.m4a']
        },
  
  ];

  const movie = details[id];


  return (
    <div>
      <h1><b><i>{movie.Title} By {movie.Actor}</i></b></h1>
      <img src={movie.image} alt={movie.Title}/>
      <p>Actor: {movie.Actor}</p>

      <p>Registered Trademark of {movie.Actor}</p>
      <p>Movie songs: {movie.songs}</p>
      <AudioPlayer songs={movie.songs} />
      <button onClick={() => window.location.href = '/Spotify1'}>Go Back</button>
    </div>
  );
};

export default MovieDetail;
