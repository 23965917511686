import React, { useRef } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Spotify1 from './Spotify1';
import Spotify from './Spotify';
import MultiActionAreaCard from './Moviescard';
import MovieDetails from './MovieDetails';
import './all.css';
import './App.css'; 
import Welcome from './Welcome';
import Player from './AudioPlayer';
import SearchLyrics from './searchLyrics';
import Weather1 from './Weather1';
const App = () => {
  const ref = useRef(null);
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Welcome/>} />
        <Route path="/movie/:id" element={<MovieDetails />} />
        <Route path="/spotify1" element={<Spotify1 />} />
        <Route path="/spotify" element={<Spotify />} />       
        <Route path="/Welcome" element={<Welcome/>} /> 
        <Route path="/searchLyrics" element={<SearchLyrics/>} /> 
        <Route path='/AudioPlayer' element={<Player/>}/>
        <Route path='/Weather1' element={<Weather1/>}/>
    </Routes>
    </BrowserRouter>
  );
};

export default App;
