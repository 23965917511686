import React, { useState } from 'react';
import img1 from '../src/sky.avif';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActions, colors, Grid, IconButton } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import axios from 'axios';
import ThermostatSharpIcon from '@mui/icons-material/ThermostatSharp';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Weather1() {
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');

  const handleSearch = async (event) => {
    event.preventDefault();
    if (!city) return;

    const response = await axios.get(
      `https://api.weatherapi.com/v1/current.json?key=788622e6477449be823115129242606&q=${city},India&aqi=yes`
    );

    setCities([...cities, response.data]);
    setCity('');
  };

  const handleDelete = (index) => {
    const newCities = [...cities];
    newCities.splice(index, 1);
    setCities(newCities);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${img1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Typography variant="h3" align="center" component={"div"} sx={{ color: 'blackgrey' }}>
        Weather Report
        <hr />
      </Typography>

      <br />
      <form onSubmit={handleSearch}>
        <input type="text" placeholder="Enter the City:" value={city} onChange={(e) => setCity(e.target.value)} />
        <button type="submit">Search</button>
      </form>
      <br />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {cities.map((val, index) => {
          return (
            <Grid item sx={3} key={index}>
              <Card sx={{ minWidth: 275 }} variant="outlined" align="left">
                
                <br />
                <CardContent style={{ backgroundColor: 'lightblue', height: 200 }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    Weather Dekh Le
                  </Typography>
                  <Typography variant="h5" component="div">
                    {val.location.name}, {val.location.region}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Temperature: {val.current.temp_c}*C
                  </Typography>
                  <Typography variant="body2">
                    Wind Flow: {val.current.wind_kph}/kph
                    <br />
                    <br />
                    <WbSunnyIcon
                      style={{ color: val.current.temp_c > 28.5 ? 'red' : 'green' }}
                      sx={{ fontSize: 40 }}
                    />
                    
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button size="large">Weather</Button>
                  <ThermostatSharpIcon color="error" />
                  <IconButton onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}