// AudioPlayer.js
import React, { useRef, useState, useEffect } from 'react';

const AudioPlayer = ({ songs }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5); // Initial volume set to 50%
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    audioRef.current.addEventListener('loadedmetadata', () => {
      setDuration(audioRef.current.duration);
    });

    audioRef.current.addEventListener('timeupdate', () => {
      setCurrentTime(audioRef.current.currentTime);
      setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
    });

    return () => {
      audioRef.current.removeEventListener('loadedmetadata');
      audioRef.current.removeEventListener('timeupdate');
    };
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

const handleSeek = (e) => {
  const newTime = e.target.value;
  setCurrentTime(newTime);
  audioRef.current.currentTime = newTime;
  const progress = (newTime / duration) * 100;
  setProgress(progress); // Update progress state for visual representation
};


  return (
    <div className="audio-player">
      <audio ref={audioRef} src={songs[0]} />
      <button onClick={togglePlayPause}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
      />
      <input
        type="range"
        min="0"
        max={duration}
        step="1"
        value={currentTime}
        onChange={handleSeek}
      />
      <div className="progress-bar">
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: 'green',
          }}
        />
      </div>
      <div>
        {currentTime.toFixed(2)} / {duration.toFixed(2)}
      </div>
    </div>
  );
};

export default AudioPlayer;